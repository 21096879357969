<template>

  <div>
    <b-row>
      <b-col md="6" sm="12">
        <b-form>
          <b-row>
            <b-col md="6" sm="12">
              <b-form-group>
                <label for="nama_kontak">Nama Lengkap</label>
                <b-form-input v-model="markers[0].nama_kontak" id="nama_kontak" type="text"
                  placeholder="Nama Lengkap" />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group>
                <label for="nomor_kontak">No. Telepon (Aktif)</label>
                <b-form-input v-model="markers[0].nomor_kontak" id="nomor_kontak" type="text"
                  placeholder="Nomor Telepon" />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group>
                <label for="label_alamat">Label Alamat</label>
                <b-form-input v-model="markers[0].label_alamat" id="label_alamat" type="text"
                  placeholder="Rumah, Kantor, Apartemen" />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group>
                <label for="jenis">Jenis Alamat</label>
                <b-form-radio v-model="markers[0].jenis_alamat" name="jenis_alamat" value="utama" disabled>
                  Utama
                </b-form-radio>
                <b-form-radio v-model="markers[0].jenis_alamat" name="jenis_alamat" value="toko">
                  Toko
                </b-form-radio>
                <b-form-radio v-model="markers[0].jenis_alamat" name="jenis_alamat" value="retur" disabled>
                  Retur
                </b-form-radio>
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Provinsi" label-for="v-provinsi">
                <v-select v-model="markers[0].provinsi_id" :reduce="(option) => option.value" label="name"
                  :options="optprovinsi" @input="getkabkota()" />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Kabupaten/Kota" label-for="v-kabko">
                <v-select v-model="markers[0].kab_kota_id" :reduce="(option) => option.value" label="name"
                  :options="optkabko" @input="getkecamatan()" />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Kecamatan" label-for="v-kecamatan">
                <v-select v-model="markers[0].kecamatan_id" :reduce="(option) => option.value" label="name"
                  :options="optkec" @input="getkelurahan()" />
              </b-form-group>
            </b-col>
            <b-col md="6" sm="12">
              <b-form-group label="Kelurahan/Desa" label-for="v-kel">
                <v-select v-model="markers[0].desa_id" :reduce="(option) => option.value" label="name"
                  :options="optkel" />
              </b-form-group>
            </b-col>
            <b-col md="12" sm="12">
              <b-form-group>
                <label for="alamat">Alamat</label>
                <b-form-textarea v-model="markers[0].alamat" id="alamat"
                  placeholder="Nama Jalan, Gang, Gedung, No Rumah" />
              </b-form-group>
            </b-col>
            <b-col md="12" sm="12">
              <b-form-group>
                <label for="reward">Catatan</label>
                <b-form-textarea v-model="markers[0].catatan" id="deskripsi"
                  placeholder="Catatan lainnya, Contoh (Nomor blok, Titip ke satpam rumah, Patokan)" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <b-button size="sm" class="mr-1" variant="primary" @click="submit"> Simpan </b-button>
      </b-col>
      <!-- <p id="demo" v-text="text"></p> -->
      <b-col md="6" sm="12">
        <b-button size="sm" class="mr-1" variant="primary" @click="getLocation()" block> Perbarui Lokasi </b-button>
        <b-row>
          <b-col md="6" sm="12">
            <b-form-group>
              <label for="positionla">Latitude</label>
              <b-form-input v-model="markers[0].position.lat" id="positionla" type="text" readonly />
            </b-form-group>
          </b-col>
          <b-col md="6" sm="12">
            <b-form-group>
              <label for="positionlo">Longitude</label>
              <b-form-input v-model="markers[0].position.lng" id="positionlo" type="text" readonly />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" sm="12">
            <b-overlay :show="loadingmap">
              <!-- <table>
        <tr>
          <th>Marker</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Tooltip</th>
          <th>Is Draggable ?</th>
          <th>Is Visible ?</th>
          <th>Remove</th>
        </tr>
        <tr
          v-for="(item, index) in markers"
          :key="index"
        >
          <td>{{ 'Marker ' + (index + 1) }}</td>
          <td>
            <input
              v-model.number="item.position.lat"
              type="number"
            >
          </td>
          <td>
            <input
              v-model.number="item.position.lng"
              type="number"
            >
          </td>
          <td>
            <input
              v-model="item.tooltip"
              type="text"
            >
          </td>
          <td style="text-align: center">
            <input
              v-model="item.draggable"
              type="checkbox"
            >
          </td>
          <td style="text-align: center">
            <input
              v-model="item.visible"
              type="checkbox"
            >
          </td>
          <td style="text-align: center">
            <input
              type="button"
              value="X"
              @click="removeMarker(index)"
            >
          </td>
        </tr>
      </table> -->
              <l-map v-if="mode == 1" :zoom.sync="zoom" :options="mapOptions" :center="center" :min-zoom="minZoom"
                :max-zoom="maxZoom" style="height: 500px; width: 100%">
                <l-control-layers :position="layersPosition" :collapsed="false" :sort-layers="true" />
                <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name"
                  :visible="tileProvider.visible" :url="tileProvider.url" :attribution="tileProvider.attribution"
                  :token="token" layer-type="base" />
                <l-control-zoom :position="zoomPosition" />
                <l-control-attribution :position="attributionPosition" :prefix="attributionPrefix" />
                <l-control-scale :imperial="imperial" />
                <l-marker v-if="mode == 1" v-for="marker in markers" :key="marker.id" :visible="marker.visible"
                  :draggable="marker.draggable" :lat-lng.sync="marker.position" 
                  :icon="marker.icon">
                  <!-- @click="alert(marker)" -->
                  <!-- :icon="marker.icon" -->
                  <l-popup :content="marker.tooltip" />
                  <l-tooltip :content="marker.tooltip" />
                </l-marker>
                <!-- <l-layer-group
        layer-type="overlay"
        name="Layer polyline"
      >
        <l-polyline
          v-for="item in polylines"
          :key="item.id"
          :lat-lngs="item.points"
          :visible="item.visible"
          @click="alert(item)"
        />
      </l-layer-group>
      <l-layer-group
        v-for="item in stuff"
        :key="item.id"
        :visible.sync="item.visible"
        layer-type="overlay"
        name="Layer 1"
      >
        <l-layer-group :visible="item.markersVisible">
          <l-marker
            v-for="marker in item.markers"
            :key="marker.id"
            :visible="marker.visible"
            :draggable="marker.draggable"
            :lat-lng="marker.position"
            @click="alert(marker)"
          />
        </l-layer-group>
        <l-polyline
          :lat-lngs="item.polyline.points"
          :visible="item.polyline.visible"
          @click="alert(item.polyline)"
        />
      </l-layer-group> -->
              </l-map>
            </b-overlay>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
  </div>
</template>

<script>
// import { useGeolocation } from '@vueuse/core'
// const { coords, locatedAt, error, resume, pause } = useGeolocation()
import vSelect from "vue-select";
import { latLngBounds, icon } from 'leaflet';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolyline,
  LLayerGroup,
  LTooltip,
  LPopup,
  LControlZoom,
  LControlAttribution,
  LControlScale,
  LControlLayers
} from 'vue2-leaflet';
import { BOverlay, BTable, BCard, BCardTitle, BCardText, BFormTextarea, BFormRadio, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton } from "bootstrap-vue";


const markers1 = [
  {
    position: { lng: -1.219482, lat: 47.41322 },
    visible: true,
    draggable: true,
  },
  { position: { lng: -1.571045, lat: 47.457809 } },
  { position: { lng: -1.560059, lat: 47.739323 } },
  { position: { lng: -0.922852, lat: 47.886881 } },
  { position: { lng: -0.769043, lat: 48.231991 } },
  { position: { lng: 0.395508, lat: 48.268569 } },
  { position: { lng: 0.604248, lat: 48.026672 } },
  { position: { lng: 1.2854, lat: 47.982568 } },
  { position: { lng: 1.318359, lat: 47.894248 } },
  { position: { lng: 1.373291, lat: 47.879513 } },
  { position: { lng: 1.384277, lat: 47.798397 } },
  { position: { lng: 1.329346, lat: 47.754098 } },
  { position: { lng: 1.329346, lat: 47.680183 } },
  { position: { lng: 0.999756, lat: 47.635784 } },
  { position: { lng: 0.86792, lat: 47.820532 } },
  { position: { lng: 0.571289, lat: 47.820532 } },
  { position: { lng: 0.439453, lat: 47.717154 } },
  { position: { lng: 0.439453, lat: 47.61357 } },
  { position: { lng: -0.571289, lat: 47.487513 } },
  { position: { lng: -0.615234, lat: 47.680183 } },
  { position: { lng: -0.812988, lat: 47.724545 } },
  { position: { lng: -1.054688, lat: 47.680183 } },
  { position: { lng: -1.219482, lat: 47.41322 } },
];

const poly1 = [
  { lng: -1.219482, lat: 47.41322 },
  { lng: -1.571045, lat: 47.457809 },
  { lng: -1.560059, lat: 47.739323 },
  { lng: -0.922852, lat: 47.886881 },
  { lng: -0.769043, lat: 48.231991 },
  { lng: 0.395508, lat: 48.268569 },
  { lng: 0.604248, lat: 48.026672 },
  { lng: 1.2854, lat: 47.982568 },
  { lng: 1.318359, lat: 47.894248 },
  { lng: 1.373291, lat: 47.879513 },
  { lng: 1.384277, lat: 47.798397 },
  { lng: 1.329346, lat: 47.754098 },
  { lng: 1.329346, lat: 47.680183 },
  { lng: 0.999756, lat: 47.635784 },
  { lng: 0.86792, lat: 47.820532 },
  { lng: 0.571289, lat: 47.820532 },
  { lng: 0.439453, lat: 47.717154 },
  { lng: 0.439453, lat: 47.61357 },
  { lng: -0.571289, lat: 47.487513 },
  { lng: -0.615234, lat: 47.680183 },
  { lng: -0.812988, lat: 47.724545 },
  { lng: -1.054688, lat: 47.680183 },
  { lng: -1.219482, lat: 47.41322 },
];

const tileProviders = [
  {
    name: 'OpenStreetMap',
    visible: true,
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  },
  {
    name: 'OpenTopoMap',
    visible: false,
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    attribution:
      'Map data: &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)',
  },
];

export default {
  name: 'Example',
  components: {
    // UseGeolocation,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LLayerGroup,
    LTooltip,
    LPopup,
    LControlZoom,
    LControlAttribution,
    LControlScale,
    LControlLayers,
    BOverlay, BTable, BCard, BCardTitle, BCardText, BFormTextarea, BFormRadio, VBTooltip, BAvatar, BBadge, BModal, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton
  },
  data() {
    return {
      loadingmap: false,
      form: {
        label_alamat: null, // OPTIONAL
        provinsi_id: null,
        latitude: null,  // OPTIONAL
        longitude: null,  // OPTIONAL
        kab_kota_id: null,
        kecamatan_id: null,
        desa_id: null, // OPTIONAL
        alamat: null,
        nomor_kontak: null,
        nama_kontak: null,
        jenis_alamat: 'toko', // PILIHAN utama toko retur
        catatan: null, // OPTIONAL
      },
      mode: 0,
      text: 'Hier wird deine Latitude und Longitude angezeigt.',
      lat: '',
      lng: '',
      center: [51.505, -0.09],
      opacity: 0.6,
      token: 'your token if using mapbox',
      mapOptions: {
        zoomControl: false,
        attributionControl: false,
        zoomSnap: true,
      },
      zoom: 15,
      minZoom: 1,
      maxZoom: 20,
      zoomPosition: 'topleft',
      attributionPosition: 'bottomright',
      layersPosition: 'topright',
      attributionPrefix: 'Vue2Leaflet',
      imperial: false,
      Positions: ['topleft', 'topright', 'bottomleft', 'bottomright'],
      tileProviders: tileProviders,
      markers: [
        {
          // id: 'm1',
          position: { lat: 51.505, lng: -0.09 },
          tooltip: 'Posisi kamu disini',
          draggable: true,
          visible: true,
          icon: icon({
            iconUrl: "/assets/gps.png",
            iconSize: [32, 37],
            iconAnchor: [16, 37]
          }),
          label_alamat: null, // OPTIONAL
          provinsi_id: null,
          latitude: null,  // OPTIONAL
          longitude: null,  // OPTIONAL
          kab_kota_id: null,
          kecamatan_id: null,
          desa_id: null, // OPTIONAL
          alamat: null,
          nomor_kontak: null,
          nama_kontak: null,
          jenis_alamat: 'toko', // PILIHAN utama toko retur
          catatan: null, // OPTIONAL
        },
        // {
        //   id: 'm2',
        //   position: { lat: 51.8905, lng: -0.09 },
        //   tooltip: 'tooltip for marker2',
        //   draggable: true,
        //   visible: false,
        //   icon: icon({
        //     iconUrl: "/assets/gps.png",
        //     iconSize: [32, 37],
        //     iconAnchor: [16, 37]
        //   }),
        // },
        // {
        //   id: 'm3',
        //   position: { lat: 51.005, lng: -0.09 },
        //   tooltip: 'tooltip for marker3',
        //   draggable: true,
        //   visible: true,
        //   icon: icon({
        //     iconUrl: "/assets/gps.png",
        //     iconSize: [32, 37],
        //     iconAnchor: [16, 37]
        //   }),
        // },
        // {
        //   id: 'm4',
        //   position: { lat: 50.7605, lng: -0.09 },
        //   tooltip: 'tooltip for marker4',
        //   draggable: true,
        //   visible: false,
        //   icon: icon({
        //     iconUrl: "/assets/gps.png",
        //     iconSize: [32, 37],
        //     iconAnchor: [16, 37]
        //   }),
        // },
      ],
      polylines: [
        {
          id: 'p1',
          points: [
            { lat: 37.772, lng: -122.214 },
            { lat: 21.291, lng: -157.821 },
            { lat: -18.142, lng: -181.569 },
            { lat: -27.467, lng: -206.973 },
          ],
          visible: true,
        },
        {
          id: 'p2',
          points: [
            [-73.91, 40.78],
            [-87.62, 41.83],
            [-96.72, 32.76],
          ],
          visible: true,
        },
      ],
      stuff: [
        {
          id: 's1',
          markers: markers1,
          polyline: { points: poly1, visible: true },
          visible: true,
          markersVisible: true,
        },
      ],
      bounds: latLngBounds(
        { lat: 51.476483373501964, lng: -0.14668464136775586 },
        { lat: 51.52948330894063, lng: -0.019140238291583955 }
      ),
      optprovinsi: [],
      optkabko: [],
      optkec: [],
      optkel: [],
    };
  },
  methods: {
    alert(item) {
      alert('this is ' + JSON.stringify(item));
    },
    // icon({
    //     iconUrl= "/assets/gps.png",
    //     iconSize= [32, 37],
    //     iconAnchor= [16, 37]
    //   }),
    addMarker: function () {
      const newMarker = {
        position: { lat: this.lat, lng: this.lng },
        draggable: true,
        visible: true,
      };
      this.markers.push(newMarker);
    },
    removeMarker: function (index) {
      this.markers.splice(index, 1);
    },
    fitPolyline: function () {
      const bounds = latLngBounds(markers1.map(o => o.position));
      this.bounds = bounds;
    },
    getLocation() {
      this.loadingmap = true
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(this.showPosition);
      } else {
        text = "Geolocation is not supported by this browser.";
      }
      // this.showPosition()
    },
    showPosition(position) {
      this.lat = position.coords.latitude,
        this.lng = position.coords.longitude,
        this.text = "deine Location befindet sich in: Latitude:" + this.lat + " und Longitude: " + this.lng;
      this.markers[0].position.lat = this.lat
      this.markers[0].position.lng = this.lng
      this.center = [this.lat, this.lng]
      // console.log('AJI', this.center)
      this.mode = 1
      this.displayInfo({
        message: 'Lokasi sudah terbarui'
      })
      this.loadingmap = false
    },
    async getProvinsi() {
      let provinsi = await this.$store.dispatch("wilayah/getprovinsi");
      provinsi = JSON.parse(JSON.stringify(provinsi));
      let newprovinsi = [];
      provinsi.map((item) => {
        newprovinsi.push({
          name: item.name,
          value: item.id,
        });
      });
      // this.id_provinsi = newprovinsi;
      this.optprovinsi = newprovinsi;
      // });
    },
    async getkabkota() {
      const params = {
        id_provinsi: this.markers[0].provinsi_id ? this.markers[0].provinsi_id : null
      }
      let kabko = await this.$store.dispatch("wilayah/getkabko", params);
      kabko = JSON.parse(JSON.stringify(kabko));
      let newkabko = [];
      kabko.map((item) => {
        newkabko.push({
          name: item.name,
          value: item.id,
        });
      });
      this.optkabko = newkabko;
    },
    async getkecamatan() {
      const params = {
        id_kab_kota: this.markers[0].kab_kota_id ? this.markers[0].kab_kota_id : null
      }
      let kec = await this.$store.dispatch("wilayah/getkecamatan", params);
      kec = JSON.parse(JSON.stringify(kec));
      let newkec = [];
      kec.map((item) => {
        newkec.push({
          name: item.name,
          value: item.id,
        });
      });
      this.optkec = newkec;
    },
    async getkelurahan() {
      const params = {
        id_kecamatan: this.markers[0].kecamatan_id
      }
      let kel = await this.$store.dispatch("wilayah/getkelurahan", params);
      kel = JSON.parse(JSON.stringify(kel));
      let newkel = [];
      kel.map((item) => {
        newkel.push({
          name: item.name,
          value: item.id,
        });
      });
      this.optkel = newkel;
    },
    submit() {
      if (
        this.markers[0].label_alamat == null ||
        this.markers[0].provinsi_id == null ||
        this.markers[0].kab_kota_id == null ||
        this.markers[0].kecamatan_id == null ||
        this.markers[0].desa_id == null ||
        this.markers[0].alamat == null ||
        this.markers[0].nomor_kontak == null ||
        this.markers[0].nama_kontak == null ||
        this.markers[0].catatan == null) {
        this.displayInfo({
          message: 'Harap lengkapi form tersedia'
        });
        return false;
      }
      if (this.id) {
        this.markers[0].id = this.id
      }
      const payload = this.markers[0]
      payload.member_id = this.user.member_id
      payload.latitude = this.markers[0].position.lat
      payload.longitude = this.markers[0].position.lng

      this.$store
        .dispatch("alamat/save", payload)
        .then(() => {
          // if (this.activeAction == "tambah") {
          //   this.id = null;
          // }          
          this.markers[0].label_alamat == null;
          this.markers[0].provinsi_id == null;
          this.markers[0].latitude == null;
          this.markers[0].longitude == null;
          this.markers[0].kab_kota_id == null;
          this.markers[0].kecamatan_id == null;
          this.markers[0].desa_id == null;
          this.markers[0].alamat == null;
          this.markers[0].nomor_kontak == null;
          this.markers[0].nama_kontak == null;
          this.markers[0].jenis_alamat == 'toko';
          this.markers[0].catatan == null;
          this.displaySuccess({
            text: "Alamat berhasil diperbarui !",
          });
          setTimeout(() => this.$router.push(`/akun-pengguna?tab=alamat`), 1000);
        })
        .catch((e) => console.error(e));
    },
    getDataId() {
      // if (this.isCabang || this.isMarketing) {
      //   var params = {
      //     member_id: this.user.member_id,
      //     jenis_alamat: 'toko',
      //   };
      // } else {
      //   var params = {};
      // }
      this.loadingmap = true
      this.$store
        .dispatch("alamat/getDataById", this.$route.params.id)
        .then((response) => {
          let data = response
          data.position = { lat : response.latitude, lng : response.longitude }
          data.tooltip= 'Posisi kamu disini',
          data.draggable= true,
          data.visible= true,
          data.icon= icon({
            iconUrl: "/assets/gps.png",
            iconSize: [32, 37],
            iconAnchor: [16, 37]
          }),
          // data.position.lng = response.longitude
          this.markers = [data]
      //     this.markers[0].position.lat = this.lat
      // this.markers[0].position.lng = this.lng
      this.center = [response.latitude, response.longitude]
      this.mode = 1
    this.getProvinsi()
    this.getkabkota()
    this.getkecamatan()
    this.getkelurahan()
      this.loadingmap = false
          // console.log('cek',this.markers)
          // this.getLocation()
          // this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
  },
  mounted() {
    // this.getLocation()
    this.getDataId()
  },
  created() {
    // this.getLocation()
  }
};
</script>
